import { callDeepLink } from '@croquiscom/web2app';

import { getAdjustedBrowsingType } from '@common/app-manager';
import { isZigzag } from '@common/device-manager';
import { Log, useUBL } from '@common/log-manager';
import {
  formatCategoryList,
  InAppLikeProductPayload,
  useLikeProductService,
  WebLikeProductPayload,
} from '@widgets/like-product';
import { type UxGoodsCardItemForVerticalProductCard } from '@widgets/product-card';

import { UxBrowsingType } from '../types';

interface UseProductCardEventsProps {
  onImpression: (item: UxGoodsCardItemForVerticalProductCard, index?: number, log?: Partial<Log>) => void;
  onClick: (
    event: React.MouseEvent<HTMLElement>,
    item: UxGoodsCardItemForVerticalProductCard,
    index?: number,
    log?: Partial<Log>,
  ) => void;
  onLikeClick: (
    isLiked: boolean,
    item: UxGoodsCardItemForVerticalProductCard,
    index?: number,
    log?: Partial<Log>,
  ) => void;
}

export const useProductCardEvents = (): UseProductCardEventsProps => {
  const { onLikeProduct } = useLikeProductService();
  const tracker = useUBL();

  const handleProductClick: UseProductCardEventsProps['onClick'] = (event, item, index, log) => {
    const { catalog_product_id, browsing_type, product_url, shop_product_no, shop_id } = item;
    if (log != null) {
      tracker({
        navigation: '',
        ...log,
        category: 'click',
        object_type: 'catalog',
        object_id: catalog_product_id ?? '',
        ...(index !== undefined && { object_idx: index }),
      });
    }
    if (isZigzag()) {
      event.preventDefault();
      switch (browsing_type) {
        case UxBrowsingType.Z_BROWSER:
          return callDeepLink('/product_detail')({
            browsing_type,
            url: product_url,
            shop_id,
            shop_product_no,
            catalog_product_id,
          });
        case UxBrowsingType.NATIVE_BROWSER:
        case UxBrowsingType.DETAIL_BROWSER:
        case UxBrowsingType.INTERNAL_BROWSER:
          return callDeepLink('/product_detail')({
            browsing_type: getAdjustedBrowsingType(browsing_type, product_url),
            url: window.location.origin + `/catalog/products/${catalog_product_id}`,
            shop_id,
            shop_product_no,
            catalog_product_id,
          });
        default:
          return callDeepLink(`${product_url}`)();
      }
    }
  };

  const handleProductReveal: UseProductCardEventsProps['onImpression'] = (item, index, log) => {
    const { catalog_product_id } = item;

    tracker({
      navigation: '',
      ...log,
      category: 'impression',
      object_type: 'catalog',
      object_id: catalog_product_id ?? '',
      ...(index !== undefined && { object_idx: index }),
    });
  };

  const handleProductLikeClick: UseProductCardEventsProps['onLikeClick'] = (isLiked, item, index, log) => {
    const inAppLikeProductPayload: InAppLikeProductPayload = {
      like: isLiked,
      browsingType: item.browsing_type,
      shopId: Number(item.shop_id),
      shopProductNo: item.shop_product_no ?? null,
      catalogProductId: item.catalog_product_id,
      title: item.title ?? '',
      goodsUrl: item.product_url ?? '',
      imageUrl: item.image_url ?? '',
      price: Number(item.final_price),
      discountRate: Number(item.discount_rate),
      isZpay: Boolean(item.zpay),
      isFreeShipping: Boolean(item.free_shipping),
    };

    const webLikeProductPayload: WebLikeProductPayload = {
      checked: isLiked,
      productData: { catalog_product_id: item.catalog_product_id ?? '', shop_id: item.shop_id ?? '' },
      airbridge: {
        brandID: item.shop_id ?? '',
        brandName: item.shop_name ?? '',
        productID: item.catalog_product_id,
        productName: item.title,
        price: item.final_price,
        categoryID: formatCategoryList(item.managed_category_list, 'category_id'),
        categoryName: formatCategoryList(item.managed_category_list, 'value'),
      },
      ...(log && {
        sendLog: (isLiked) =>
          tracker({
            navigation: '',
            ...log,
            category: isLiked ? 'save_product' : 'remove_saved_product',
            object_type: 'catalog',
            object_id: item.catalog_product_id ?? undefined,
            ...(index !== undefined && { object_idx: index }),
          }),
      }),
    };

    onLikeProduct({ inAppLikeProductPayload, webLikeProductPayload });
  };

  return {
    onImpression: handleProductReveal,
    onClick: handleProductClick,
    onLikeClick: handleProductLikeClick,
  };
};
